import React from 'react';

import laundroLogo from '../images/logo/laundrologo2.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { USER_LOGOUT_SUCCESS, addActivity } from '../reducer/userReducer';
import { Dropdown, Space } from 'antd';

const HeaderBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log('location', location && location.state);
  const onClick = ({ key }) => {
    if (Number(key) === 1) {
      return navigate('/user/management', {
        state: location && location.state,
      });
    }
    if (Number(key) === 2) {
      return navigate('/global/config', {
        state: location && location.state,
      });
    }

    if (Number(key) === 3) {
      dispatch(addActivity({ type: 'logout' }));

      if (location && location.state?.type === 'admin') {
        setTimeout(() => {
          localStorage.removeItem('laundro-admin-node-token');
        }, 1500);
        return navigate('/admin/login');
      } else {
        dispatch({ type: USER_LOGOUT_SUCCESS });
        setTimeout(() => {
          localStorage.removeItem('laundro-node-token');
        }, 1500);
        return navigate('/login');
      }
    }
  };

  // console.log('location.stat', location.state);

  const items =
    location && location.state?.type === 'admin'
      ? [
          {
            label: 'User Management',
            key: 1,
          },
          // {
          //   label: 'Global Config Page',
          //   key: 2,
          // },
          {
            label: 'Log out',
            key: 3,
          },
        ]
      : location.state?.type === 'developer'
      ? [
          {
            label: 'Log out',
            key: 3,
          },
          {
            label: 'Global Config Page',
            key: 2,
          },
        ]
      : [
          {
            label: 'Log out',
            key: 3,
          },
        ];

  return (
    <div style={{ background: '#333' }}>
      <div className="container d-flex justify-content-between align-items-center py-2">
        <div
          onClick={() =>
            navigate('/selectOutlet', {
              state: location && location.state,
            })
          }
          style={{ cursor: 'pointer' }}
        >
          <img
            src={laundroLogo}
            style={{ height: '40px', objectFit: 'fill' }}
            alt="homeLogo"
          />
        </div>

        {window.location.pathname === '/login' ||
        window.location.pathname === '/register' ||
        window.location.pathname === '/admin/login' ? (
          <div></div>
        ) : (
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <div onClick={(e) => e.preventDefault()}>
              <Space className="text-white">
                Profile
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default HeaderBar;

import axios from 'axios';
import { tokenConfig, returnError } from './helperFunc';

const SET_LOADING = 'SET_LOADING';
const CLEAR_ERROR = 'CLEAR_ERROR';

const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';

const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

const USER_DETAILS_FETCH_SUCCESS = 'USER_DETAILS_FETCH_SUCCESS';
const USER_DETAILS_FETCH_FAIL = 'USER_DETAILS_FETCH_FAIL';

const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';

const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
const ADD_ACTIVITY_FAIL = 'ADD_ACTIVITY_FAIL';

export const register = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(`/api/users/register`, newUser);
    // console.log('data', data.data)
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: USER_REGISTER_FAIL, payload: returnError(error) });
  }
};

export const loginUser = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(`/api/users/login`, newUser);

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: USER_LOGIN_FAIL, payload: returnError(error) });
  }
};

export const userDetails = (userId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const config = tokenConfig();
    const { data } = await axios.get(`/api/users/userDetails`, config);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: USER_DETAILS_FAIL, payload: returnError(error) });
  }
};

export const getUserDetailsData = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const config = tokenConfig();
    const { data } = await axios.get(`/api/users/fetch/user`, config);

    dispatch({ type: USER_DETAILS_FETCH_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: USER_DETAILS_FETCH_FAIL, payload: returnError(error) });
  }
};

export const updateUserDetails = (reqBody) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const config = tokenConfig('admin');
    const { data } = await axios.patch(
      `/api/users/update/user`,
      reqBody,
      config
    );

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: USER_UPDATE_FAIL, payload: returnError(error) });
  }
};

export const addActivity = (reqBody) => async (dispatch) => {
  try {
    const config = tokenConfig();
    const { data } = await axios.post(
      `/api/users/add/activity`,
      reqBody,
      config
    );
    // console.log('data', data);
    dispatch({ type: ADD_ACTIVITY_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: ADD_ACTIVITY_FAIL, payload: returnError(error) });
  }
};

const initialState = {
  language: 'en',
  user: null,
  error: null,
  loading: false,
  outlets: [],
  currentUser: null,
};

export const userReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    // user login and registration success
    case USER_LOGIN_SUCCESS:
      if (action.payload.type === 'admin') {
        // console.log('action.payload.', action.payload);
        localStorage.setItem('laundro-admin-node-token', action.payload.token);
      } else {
        // console.log('action.payload.', action.payload);
        localStorage.setItem('laundro-node-token', action.payload.token);
      }
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: false,
      };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    case USER_DETAILS_FETCH_SUCCESS:
      return {
        currentUser: action.payload,
        loading: false,
        error: false,
      };
    case USER_REGISTER_SUCCESS:
      return {
        user: action.payload.userCreated,
        loading: false,
        error: false,
      };

    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        outlets: action.payload,
        loading: false,
        error: false,
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: false,
      };

    // user auth error and logout
    case USER_REGISTER_FAIL:
    case USER_UPDATE_FAIL:
    case USER_DETAILS_FAIL:
    case USER_LOGIN_FAIL:
    case USER_DETAILS_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

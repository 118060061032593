import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './App.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './reducer/userReducer';
import { outletReducer } from './reducer/outletReducer';
import { adminReducer } from './reducer/adminReducer';
import { globalConfigReducer } from './reducer/globalConfigReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    outlet: outletReducer,
    admin: adminReducer,
    globalConfig: globalConfigReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();

import React, { useState, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, message, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../reducer/userReducer';
import { useNavigate } from 'react-router-dom';
import HeaderBar from './HeaderBar';

const AdminLogin = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);

  const [form] = Form.useForm();

  const { user, error } = useSelector((state) => state.user);

  const onFinish = (values) => {
    setSubmit(true);
    let data = values;
    data.admin = 'admin';

    dispatch(loginUser(data));
  };

  // console.log('user', user);

  useEffect(() => {
    if (user && submit) {
      setSubmit(false);
      setTimeout(() => {
        navigate('/user/management', { state: user });
      }, 1000);
    }
  }, [user, submit, navigate]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  // console.log('error', error);

  return (
    <div>
      <HeaderBar />
      <div className="pt-3 pb-4">
        <h2 className="text-center">Laundro Node Cloud</h2>
      </div>

      <div className="d-flex justify-content-center">
        <div className="card col-md-5 col-sm-10  p-5 mx-3">
          <div style={{ marginBottom: '1.5rem' }} className="form-header">
            <h2 className="text-center">Admin Login</h2>
            <p className="mt-3">
              Please enter your user name and password to login
            </p>
          </div>
          <Form
            name="admin_login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                block
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

import axios from 'axios';
import { tokenConfig, headerConfig, returnError } from './helperFunc';

const SET_ADMIN_LOADING = 'SET_ADMIN_LOADING';
const CLEAR_ADMIN_ERROR = 'CLEAR_ADMIN_ERROR';

const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
const GET_ADMINS_FAIL = 'GET_ADMINS_FAIL';

const GET_USERS_BY_ADMIN_ID_SUCCESS = 'GET_USERS_BY_ADMIN_ID_SUCCESS';
const GET_USERS_BY_ADMIN_ID_FAIL = 'GET_USERS_BY_ADMIN_ID_FAIL';

export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';

const FETCH_OUTLETS_SUCCESS = 'FETCH_OUTLETS_SUCCESS';
const FETCH_OUTLETS_FAIL = 'FETCH_OUTLETS_FAIL';

const ASSIGN_OUTLETS_SUCCESS = 'ASSIGN_OUTLETS_SUCCESS';
const ASSIGN_OUTLETS_FAIL = 'ASSIGN_OUTLETS_FAIL';

const FETCH_ASSIGNED_OUTLETS_SUCCESS = 'FETCH_ASSIGNED_OUTLETS_SUCCESS';
const FETCH_ASSIGNED_OUTLETS_FAIL = 'FETCH_ASSIGNED_OUTLETS_FAIL';

const FETCH_ADMIN_DETAILS_SUCCESS = 'FETCH_ADMIN_DETAILS_SUCCESS';
const FETCH_ADMIN_DETAILS_FAIL = 'FETCH_ADMIN_DETAILS_FAIL';

export const getAdminDetails = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADMIN_ERROR });
    dispatch({ type: SET_ADMIN_LOADING });

    const config = tokenConfig('admin');
    const { data } = await axios.get(`/api/admin/fetch/details`, config);

    dispatch({ type: FETCH_ADMIN_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_ADMIN_DETAILS_FAIL, payload: returnError(error) });
  }
};

export const getAdminsList = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADMIN_ERROR });
    dispatch({ type: SET_ADMIN_LOADING });

    const config = headerConfig();
    const { data } = await axios.get(`/api/admin/fetch`, config);

    dispatch({ type: GET_ADMINS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ADMINS_FAIL, payload: returnError(error) });
  }
};

export const getUsersByAdminId = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADMIN_ERROR });
    dispatch({ type: SET_ADMIN_LOADING });

    const config = tokenConfig('admin');
    const { data } = await axios.get(`/api/admin/fetch/users`, config);

    dispatch({ type: GET_USERS_BY_ADMIN_ID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_USERS_BY_ADMIN_ID_FAIL, payload: returnError(error) });
  }
};

export const assignOutlets = (resBody) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADMIN_ERROR });
    dispatch({ type: SET_ADMIN_LOADING });

    const config = tokenConfig('admin');
    const { data } = await axios.post(
      `/api/admin/assign/outlets`,
      resBody,
      config
    );

    dispatch({ type: ASSIGN_OUTLETS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ASSIGN_OUTLETS_FAIL, payload: returnError(error) });
  }
};

export const fetchAssignOutlets = (resBody) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ADMIN_ERROR });
    dispatch({ type: SET_ADMIN_LOADING });

    const config = tokenConfig('admin');
    const { data } = await axios.get(
      `/api/admin/assigned/outlets?userId=${resBody}`,
      config
    );

    dispatch({ type: FETCH_ASSIGNED_OUTLETS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FETCH_ASSIGNED_OUTLETS_FAIL,
      payload: returnError(error),
    });
  }
};

// /assign/outlets

const initialState = {
  language: 'en',
  admin: null,
  error: null,
  loading: false,
  admins: [],
  users: [],
  outlets: [],
};

export const adminReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_ADMIN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_ADMIN_ERROR:
      return {
        ...state,
        error: null,
      };

    case FETCH_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        admin: action.payload.data,
        loading: false,
        error: false,
      };
    // user login and registration success
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload.data,
        loading: false,
        error: false,
      };

    case GET_USERS_BY_ADMIN_ID_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
        error: false,
      };

    case FETCH_OUTLETS_SUCCESS:
      return {
        ...state,
        outlets: action.payload.data,
        loading: false,
        error: false,
      };

    case ASSIGN_OUTLETS_SUCCESS:
      return {
        ...state,
        assigned: action.payload.data,
        loading: false,
        error: false,
      };

    case FETCH_ASSIGNED_OUTLETS_SUCCESS:
      return {
        ...state,
        assignedOutlets: action.payload.data,
        loading: false,
        error: false,
      };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    case GET_ADMINS_FAIL:
    case GET_USERS_BY_ADMIN_ID_FAIL:
    case FETCH_OUTLETS_FAIL:
    case ASSIGN_OUTLETS_FAIL:
    case FETCH_ASSIGNED_OUTLETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

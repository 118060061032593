import axios from 'axios';
import { tokenConfig, returnError } from './helperFunc';

const SET_OUTLET_LOADING = 'SET_OUTLET_LOADING';
const CLEAR_OUTLET_ERROR = 'CLEAR_OUTLET_ERROR';

const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
const GET_DEVICES_FAIL = 'GET_DEVICES_FAIL';

const GET_OUTLET_SUCCESS = 'GET_OUTLET_SUCCESS';
const GET_OUTLET_FAIL = 'GET_OUTLET_FAIL';

const UPDATE_OUTLET_SUCCESS = 'UPDATE_OUTLET_SUCCESS';
const UPDATE_OUTLET_FAIL = 'UPDATE_OUTLET_FAIL';

const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
const UPDATE_DEVICE_FAIL = 'UPDATE_DEVICE_FAIL';

const GET_OUTLETS_SUCCESS = 'GET_OUTLETS_SUCCESS';
const GET_OUTLETS_FAIL = 'GET_OUTLETS_FAIL';

export const getOutlet = (outletId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_OUTLET_ERROR });
    dispatch({ type: SET_OUTLET_LOADING });

    const config = tokenConfig();
    const { data } = await axios.post(
      `/api/outlet/getOutlet`,
      { outletId },
      config
    );
    // console.log('data', data.data)
    dispatch({ type: GET_OUTLET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_OUTLET_FAIL, payload: returnError(error) });
  }
};

export const getDevices = (outletId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_OUTLET_ERROR });
    dispatch({ type: SET_OUTLET_LOADING });

    const config = tokenConfig();
    const { data } = await axios.post(
      `/api/outlet/getDevices`,
      { outletId },
      config
    );
    // console.log('data', data.data)
    dispatch({ type: GET_DEVICES_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_DEVICES_FAIL, payload: returnError(error) });
  }
};

export const updateOutlet = (body) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_OUTLET_ERROR });
    dispatch({ type: SET_OUTLET_LOADING });

    const config = tokenConfig();
    const { data } = await axios.post(`/api/outlet/update`, body, config);

    dispatch({ type: UPDATE_OUTLET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: UPDATE_OUTLET_FAIL, payload: returnError(error) });
  }
};

export const updateDevice = (body) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_OUTLET_ERROR });
    dispatch({ type: SET_OUTLET_LOADING });

    const config = tokenConfig();
    const { data } = await axios.post(`/api/outlet/updateDevice`, body, config);

    dispatch({ type: UPDATE_DEVICE_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: UPDATE_DEVICE_FAIL, payload: returnError(error) });
  }
};

export const updateConfiguration = (body) => async (dispatch) => {
  try {
    const config = tokenConfig();
    await axios.post(`/api/outlet/configureMachine`, body, config);
  } catch (error) {
    dispatch({ type: UPDATE_DEVICE_FAIL, payload: returnError(error) });
  }
};

export const getOutlets = (admin) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_OUTLET_ERROR });
    dispatch({ type: SET_OUTLET_LOADING });

    const config = tokenConfig(admin);
    const { data } = await axios.get(`/api/outlet/fetch/outlets`, config);
    // console.log('data', data.data)
    dispatch({ type: GET_OUTLETS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_OUTLETS_FAIL, payload: returnError(error) });
  }
};

const initialState = {
  devices: [],
  error: null,
  loading: false,
  outlets: [],
  outlet: null,
};

// laundro
// washupp
// cleanpro
// washstudio
// cuci

export const outletReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_OUTLET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_OUTLET_ERROR:
      return {
        ...state,
        error: null,
      };

    case GET_OUTLETS_SUCCESS:
      return {
        ...state,
        outlets: action.payload,
        loading: false,
        error: false,
      };

    // user login and registration success
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        loading: false,
        error: false,
      };

    case UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case UPDATE_OUTLET_SUCCESS:
    case GET_OUTLET_SUCCESS:
      // console.log('action.payload', action.payload)
      return {
        ...state,
        outlet: action.payload,
        loading: false,
        error: false,
      };

    // user auth error and logout
    case GET_DEVICES_FAIL:
    case UPDATE_OUTLET_FAIL:
    case GET_OUTLET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case UPDATE_DEVICE_FAIL:
    case GET_OUTLETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

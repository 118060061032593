// Setup config/headers and token
export const returnError = (newError) => {
  // console.log('error print here', newError);
  const errorMsg =
    newError.response && newError.response.data
      ? newError.response.data.error
      : 'Internal Error';
  return errorMsg;
};

const tokenConfig = (admin) => {
  let token = admin
    ? localStorage.getItem('laundro-admin-node-token')
    : localStorage.getItem('laundro-node-token');

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  //if there is a token then add it to the header
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

const headerConfig = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

export { tokenConfig, headerConfig };

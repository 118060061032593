import axios from 'axios';
import { tokenConfig, returnError } from './helperFunc';

const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';
const CLEAR_GLOBAL_ERROR = 'CLEAR_GLOBAL_ERROR';

const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
const GET_DEVICES_FAIL = 'GET_DEVICES_FAIL';

const UPDATE_OUTLET_SUCCESS = 'UPDATE_OUTLET_SUCCESS';
const UPDATE_OUTLET_FAIL = 'UPDATE_OUTLET_FAIL';

const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
const UPDATE_DEVICE_FAIL = 'UPDATE_DEVICE_FAIL';

const GET_GLOBAL_OUTLETS_SUCCESS = 'GET_GLOBAL_OUTLETS_SUCCESS';
const GET_GLOBAL_OUTLETS_FAIL = 'GET_GLOBAL_OUTLETS_FAIL';

const UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS';
const UPDATE_FIRMWARE_FAIL = 'UPDATE_FIRMWARE_FAIL';

const UPDATE_FIRMWARE_BY_OUTLET_SUCCESS = 'UPDATE_FIRMWARE_BY_OUTLET_SUCCESS';
const UPDATE_FIRMWARE_BY_OUTLET_FAIL = 'UPDATE_FIRMWARE_BY_OUTLET_FAIL';

const GET_ALPHA_FIRMWARE_LATEST_PERCENT_SUCCESS =
  'GET_ALPHA_FIRMWARE_LATEST_PERCENT_SUCCESS';
const GET_ALPHA_FIRMWARE_LATEST_PERCENT_FAIL =
  'GET_ALPHA_FIRMWARE_LATEST_PERCENT_FAIL';

export const getOutletsGlobalConfig = (admin) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_GLOBAL_ERROR });
    dispatch({ type: SET_GLOBAL_LOADING });

    const config = tokenConfig(admin);
    const { data } = await axios.get(`/api/global/outlets`, config);
    // console.log('data', data.data)
    dispatch({ type: GET_GLOBAL_OUTLETS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_GLOBAL_OUTLETS_FAIL, payload: returnError(error) });
  }
};

export const updateOutletGlobalConfig = (resBody) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_GLOBAL_ERROR });
    dispatch({ type: SET_GLOBAL_LOADING });

    const config = tokenConfig();
    const { data } = await axios.post(
      `/api/global/update/configuration`,
      resBody,
      config
    );
    // console.log('data', data.data)
    dispatch({ type: UPDATE_OUTLET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: UPDATE_OUTLET_FAIL, payload: returnError(error) });
  }
};

export const updateGlobalFirmwareUpdate = (resBody) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_GLOBAL_ERROR });
    dispatch({ type: SET_GLOBAL_LOADING });

    const config = tokenConfig();
    const { data } = await axios.post(
      `/api/global/update/latestFirmware`,
      resBody,
      config
    );
    // console.log('data', data.data)
    dispatch({ type: UPDATE_FIRMWARE_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: UPDATE_FIRMWARE_FAIL, payload: returnError(error) });
  }
};

export const updateGlobalFirmwareByOutlet = (resBody) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_GLOBAL_ERROR });
    dispatch({ type: SET_GLOBAL_LOADING });

    const config = tokenConfig();
    const { data } = await axios.post(
      `/api/global/outlet/update/latestFirmware`,
      resBody,
      config
    );
    // console.log('data', data.data)
    dispatch({ type: UPDATE_FIRMWARE_BY_OUTLET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: UPDATE_FIRMWARE_BY_OUTLET_FAIL,
      payload: returnError(error),
    });
  }
};

export const getAlphaFirmwareLatestPercent =
  (version, deviceModel) => async (dispatch) => {
    try {
      dispatch({ type: CLEAR_GLOBAL_ERROR });
      dispatch({ type: SET_GLOBAL_LOADING });

      const config = tokenConfig();
      const { data } = await axios.get(
        `/api/global/alphaFirmwareLatestPercent?version=${version}&deviceModel=${deviceModel}`,
        config
      );
      // console.log('data', data.data)
      dispatch({
        type: GET_ALPHA_FIRMWARE_LATEST_PERCENT_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALPHA_FIRMWARE_LATEST_PERCENT_FAIL,
        payload: returnError(error),
      });
    }
  };

const initialState = {
  error: null,
  loading: false,
  outlets: [],
};

// laundro
// washupp
// cleanpro
// washstudio
// cuci

export const globalConfigReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_GLOBAL_ERROR:
      return {
        ...state,
        error: null,
      };

    case GET_GLOBAL_OUTLETS_SUCCESS:
      return {
        ...state,
        outlets: action.payload,
        loading: false,
        error: false,
      };

    case GET_ALPHA_FIRMWARE_LATEST_PERCENT_SUCCESS:
      return {
        ...state,
        outlets: state.outlets.length
          ? state.outlets.map((outlet) => {
              const curOutlet = action.payload.find(
                (each) => each.id === outlet.id
              );

              return {
                ...outlet,
                ...curOutlet,
              };
            })
          : state.outlets,
        loading: false,
        error: false,
      };

    // user login and registration success
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        loading: false,
        error: false,
      };

    case UPDATE_DEVICE_SUCCESS:
    case UPDATE_FIRMWARE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case UPDATE_OUTLET_SUCCESS:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        outlets: state.outlets.length
          ? state.outlets.map((each) => {
              const outlet = action.payload.find((e) => e.id === each.id);
              if (outlet) {
                return {
                  ...each,
                  configurable: outlet.configurable,
                };
              } else {
                return each;
              }
            })
          : state.outlets,
        loading: false,
        error: false,
      };

    case UPDATE_FIRMWARE_BY_OUTLET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    // user auth error and logout
    case GET_DEVICES_FAIL:
    case UPDATE_OUTLET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case UPDATE_DEVICE_FAIL:
    case GET_GLOBAL_OUTLETS_FAIL:
    case UPDATE_FIRMWARE_BY_OUTLET_FAIL:
    case GET_ALPHA_FIRMWARE_LATEST_PERCENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
